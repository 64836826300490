
.sub-ttl{font-weight: 600; color: var(--primary);}
a{color: var(--primary);}
a:hover{color: var(--content);}
p{text-align: justify;}
.video-bg{background-color:#edbf72;}
.video-bdr{border: 4px solid #e5dadd;}
.section-title {
    position: relative;
    display: inline-block;
}
.text-primary {
    color:var(--primary) !important;
}
.title-tl{font-size: 17px;
    font-weight: 600;
    letter-spacing: 2px;}
    .text-secundary {
        color:#DB4242;
        font-weight: 700;
        letter-spacing: -1px;
    }

.text-uppercase {
    text-transform: uppercase !important;
}
.section-title::before {
    position: absolute;
    content: "";
    width: 45px;
    height: 2px;
    top: 50%;
    left: -55px;
    margin-top: -1px;
    background: var(--primary);
}
.section-title::after {
    position: absolute;
    content: "";
    width: 45px;
    height: 2px;
    top: 50%;
    right: -55px;
    margin-top: -1px;
    background: var(--primary);}