.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  line-height: 21px !important;
}
.css-6hp17o-MuiList-root-MuiMenu-list li {
  min-height: auto;
  line-height: 28px;
  padding: 6px 16px !important;
}
.css-4jnixx-MuiStack-root > .MuiTextField-root {
  min-width: 100% !important;
}
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 16.5px 14px 15px !important;
}
.registration-h4 {
  font-size: 18px;
  font-weight: 600;
  color: var(--sub-title);
  margin-bottom: 8px;
}
.remove-btn {
  background-color: #de2000;
  padding: 0;
  width: 28px !important;
  height: 28px !important;
  line-height: 28px;
  text-align: center;
  color: #fff;
}
.add-btn {
  background-color: #4cbb17;
  padding: 0;
  width: 28px !important;
  height: 28px !important;
  line-height: 28px;
  text-align: center;
  color: #fff;
}
.css-1c35hjw-MuiPaper-root-MuiAccordion-root.Mui-expanded {
  margin: 0 !important;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  height: 24px !important;
}
hr {
  margin: 20px 0 32px;
}
.checkbox-label {
  font-weight: 400 !important;
  font-size: 16px !important;
}
.css-1c35hjw-MuiPaper-root-MuiAccordion-root {
  box-shadow: none !important;
}
.css-1c35hjw-MuiPaper-root-MuiAccordion-root::before {
  display: none !important;
}
.css-o4b71y-MuiAccordionSummary-content {
  margin: 0px !important;
}
.css-o4b71y-MuiAccordionSummary-content.Mui-expanded {
  margin: 10px 0 !important;
}
.date-top-mrgn {
  margin-top: -2px;
  margin-bottom: 12px !important;
}
.date-label-height {
  line-height: 28px !important;
}

.spanp {
  color: var(--primary);
}
.success-heading {
  color: #00a905;
  font-weight: 600;
}
.religious-content {
  cursor: pointer;
}
.css-9l3uo3 {
  line-height: 24px !important;
  text-align: left;
}
.css-4chesp .MuiTextField-root {
  min-width: 98% !important;
}

.css-4jnixx-MuiStack-root {
  overflow: hidden !important;
}
reg-bg {
  background-color: #f9f9f9;
}
.css-md26zr-MuiInputBase-root-MuiOutlinedInput-root {
  padding-top: 2px;
}
.css-1unwuw7-MuiFormControl-root-MuiTextField-root {
  margin-top: 2px !important;
}
.css-4chesp .dropdown-margin-top {
  margin-top: 0px !important;
}
.css-4jnixx-MuiStack-root {
  margin-top: 0px !important;
}
.css-ahj2mt-MuiTypography-root {
  font-weight: 700 !important;
}
