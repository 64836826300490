.news-title-bg {
  background-color: var(--primary);
  border-radius: 10px 10px 0 0;
  padding: 2px;
}
.content-algn {
  text-align: left;
  /*background-color: var(--white);*/
  margin-bottom: 12px;
  margin-right: 16px;
}
.news-title {
  font-size: 22px;
  font-weight: 700;
}
.css-ailhql {
  /* max-height: 420px !important; */
  max-height: 380px !important;
}
.news-title1 {
  font-size: 16px;
  font-weight: 600;
  background-color: #f8f7f3;
  color: var(--sub-title);
  padding: 6px 12px;
  display: block;
  margin-bottom: 8px !important;
}
.news-title-bg-title {
  font-size: 18px !important;
  font-weight: 600 !important;
  color: var(--white) !important;
  font-family: var(--body-font-family) !important;
  text-transform: capitalize !important;
}
.news-title-right {
  border-right: 1px solid #ecbd00 !important;
}
.news-title-img {
  width: 165px;
  margin: 10px 0 12px;
}
.bottom-more {
  padding: 8px 0;
  /*border-top: 1px solid #e5e7eb;*/
  text-align: right;
  padding-right: 24px;
  /*background-color: #f2f1f8;*/
}
.news-desc p {
  margin-bottom: 12px;
  line-height: 21px;
}
.css-12dgaew {
  padding: 0px !important;
}
@media only screen and (max-width: 769px) and (min-width: 320px) {
  .news-title-bg-title {
    font-size: 16px !important;
    font-weight: 500 !important;
  }
}
