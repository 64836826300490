.css-ag7rrr-MuiTypography-root,
.css-ahj2mt-MuiTypography-root,
.MuiFormControlLabel-label {
  font-family: "Poppins", sans-serif !important;
  font-size: 15px !important;
  font-weight: 500 !important;
  color: var(--text-color2);
  display: inline;
  letter-spacing: 0px !important;
}
.css-101ca9i-MuiTypography-root-MuiLink-root {
  font-family: "Poppins", sans-serif !important;
  color: var(--primary) !important;
}
.css-1vhaqj4-MuiButtonBase-root-MuiButton-root {
  background-color: var(--primary) !important;
}
.css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked {
  color: var(--primary) !important;
}
.css-u4tvz2-MuiFormLabel-root {
  font-family: "Poppins", sans-serif !important;
  font-size: 17px !important;
  font-weight: 600 !important;
  margin-bottom: 14px;
}
.css-11lq3yg-MuiGrid-root {
  font-size: 14px;
}

.css-1vhaqj4-MuiButtonBase-root-MuiButton-root {
  margin-top: 12px !important;
  margin-bottom: 10px !important;
  font-family: "Poppins", sans-serif !important;
  padding: 10px 16px !important;
  font-size: 16px !important;
  box-shadow: none !important;
}
.css-vqmohf-MuiButtonBase-root-MuiRadio-root {
  padding: 8px 4px !important;
}
