.cal-tab {
  margin: 0 !important;
  padding: 0 !important;
  border-radius: 0 !important;
  min-height: 36px !important;
  font-size: 14px !important;
}

.th-ttl {
  border: 1px solid #e4e4e4 !important;
  text-align: center !important;
  font-weight: 600 !important;
  font-size: 15px !important;
}

.td-w {
  width: 14.2%;
  border: 1px solid #e4e4e4;
  font-weight: 400 !important;
  font-size: 13px !important;
  height: 180px;
}

.td-w span {
  font-weight: 600 !important;
  display: contents;
  color: var(--primary);
}

.td-w span:hover {
  display: contents;
}

.arr-btn-bg {
  background-color: #faf6e7 !important;
  padding: 10px;
  width: 120px;
  margin: 0 12px 0 24px;
  border-radius: 5px;
  border: 1px solid #d3c8bb !important;
}

.td-ww {
  border: 1px solid #e4e4e4;
  font-weight: 400 !important;
  font-size: 13px !important;
}

.td-width {
  width: 100px !important;
  border: 1px solid #e4e4e4;
  font-weight: 400 !important;
  font-size: 13px !important;
}

.th-ttl-list-bg {
  background-color: #eee;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}

.th-ttl-list {
  font-weight: 600 !important;
  font-size: 14px !important;
}

.th-ttl-list-right {
  float: right;
  font-size: 14px !important;
  font-weight: 600 !important;
}

.td-link {
  padding: 0px !important;
  text-transform: initial !important;
  line-height: 18px !important;
  color: rgba(0, 0, 0, 0.87) !important;
  border: none !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  text-align: left !important;
  margin-bottom: 4px;
  margin-bottom: 6px !important;
}

.td-link:hover {
  background-color: transparent !important;
}

.cal-list {
  padding: 0 !important;
  margin-top: 8px;
}

.cal-list li {
  font-size: 14px;
  line-height: 21px;
  list-style-type: none !important;
  font-weight: 400;
  margin-bottom: 6px;
  padding-left: 4px !important;
  text-align: left;
}

.cal-list li span {
  font-size: 14px !important;
  font-weight: 600;
}

@media only screen and (min-width: 340px) and (max-width: 767px) {
  .arr-btn-bg {
    display: inline;
  }

  .css-1ujnqem-MuiTabs-root {
    display: inline-flex;
  }
}

.custom-event {
  font-size: 12px;
  border-radius: 4px;
  padding: 2px;
  line-height: 2;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

}

.event-title {
  font-size: 14px;
  text-decoration: underline;


}

.rbc-agenda-view .rbc-agenda-table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

.rbc-agenda-view .rbc-agenda-table th,
.rbc-agenda-view .rbc-agenda-table td {
  padding: 10px;
  text-align: left;
  border: 1px solid #ddd;
  vertical-align: middle;
  white-space: initial;
}

.rbc-agenda-view .rbc-agenda-table th:first-child,
.rbc-agenda-view .rbc-agenda-table td:first-child {
  width: 33.5%;
  /* Ensures date column takes up 1/3rd of the width */
}

.rbc-agenda-view .rbc-agenda-table th:nth-child(2),
.rbc-agenda-view .rbc-agenda-table td:nth-child(2) {
  width: 33.5%;
  /* Ensures time column takes up 1/3rd of the width */
}

.rbc-agenda-view .rbc-agenda-table th:last-child,
.rbc-agenda-view .rbc-agenda-table td:last-child {
  width: 33.5%;
  /* Ensures event column takes up 1/3rd of the width */
}

.event-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.event-date,
.event-time,
.event-title {
  width: 33%;
  /* Each part takes up equal width */
}