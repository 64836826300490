.logo img {
  width: 150px;
}
.logo2 img {
  width: 80px;
  margin-left: 0px;
}
.bg-body-tertiary {
  padding: 0px !important;
}
.menu-link {
  margin: 0 6px;
  font-weight: 600;
  text-transform: capitalize !important;
  color: var(--text-color) !important;
  font-size: 16px !important;
}
.menu-link:hover,
.nav-link:hover {
  color: var(--title) !important;
  transition: all 2s ease;
}
.menu-dropdown {
  margin: 0 3px;
  font-weight: 500;
  text-transform: initial !important;
  color: var(--text-color) !important;
  font-size: 15px !important;
}
.nav-link {
  color: var(--text-color) !important;
  font-size: 15.5px !important;
  font-weight: 600;
}
.menu-dropdown-link {
  font-weight: 600;
  text-transform: initial !important;
  color: var(--text-color) !important;
  font-size: 15px !important;
}
.menu-dropdown-link:hover {
  transition: all 1s ease;
  font-weight: 500 !important;
  color: #fff !important;
  background-color: var(--primary) !important;
  font-size: 15px !important;
}
.dropend .dropdown-toggle::after {
  margin-left: 12px;
  font-size: 20px;
  float: right;
  margin-top: 7px;
}
.menu-dropdown a {
  padding-left: 11px;
  font-size: 15px !important;
}
.top-bg {
  background-color: var(--primary);
  height: 42px;
  line-height: 42px;
  font-size: 13.5px;
  letter-spacing: 0.5px;
}
.top-icon {
  font-size: 22px !important;
  margin-right: 4px;
  color: var(--color2);
}

.breadcrumb-bg {
  background-color: #ffedd9;
}
.breadcrumb-h1 {
  color: var(--text-color2);
  font-weight: 700;
  font-size: 23px;
  margin: 0;
}
.breadcrumb li a {
  color: var(--primary);
  padding-right: 8px;
  font-size: 14px;
}
.breadcrumb li a:hover {
  color: var(--white);
}
.active {
  color: #323232 !important;
  font-size: 14px;
}

.menu-link {
  position: relative;
  display: flex;
  align-items: center;
}

.cart-item-count {
  position: absolute;
  top: -10px;
  right: -10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  background-color: #980b38;
  border-radius: 50%;
  color: white;
  font-size: 12px;
  font-weight: bold;
  z-index: 1;
}
@media only screen and (max-width: 600px) {
  .cartIcon {
    margin-top: 20px;
  }
}
