.table {
    margin-bottom: 0px !important;
}

tr {
    border: none !important;
}

.table> :not(caption)>*>* {
    padding: 0;
    border: none;
}

.css-cdzivs-MuiButtonBase-root-MuiCheckbox-root {
    padding: 3px !important;
}

.table-align {
    margin: 0 auto;
    width: 360px;
}

.display-ttl {
    font-size: 18px !important;
    color: var(--sub-title);
    font-weight: 600 !important;
}

.stonebg {
    width: 1200px;
    height: 950px;
    background-image: url(../../../images/stone-donation-bg.jpg);
    background-repeat: no-repeat;
    border: 5px solid var(--yellow);
    background-color: #fff8ef;
    background-position: bottom;
    background-size: auto;
    padding: 18px 20px;
}

.fieldset {
    padding: 26px 14px;
    border: 1px solid #ccc;
}

.fieldset2 {
    padding: 28px 14px;
    border: 1px solid #ccc;
}

.select-w {
    margin: 5px 0;
    width: 280px;
}

.stone_amount {
    background: var(--yellow);
    padding: 9px 24px;
    max-width: 100%;
    display: block;
    border-radius: 4px;
}

.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
    width: 100% !important;
}

input:disabled~.css-doip31 {
    background-color: #f1a9a0 !important;
    border: 1px solid #b10819 !important;
}

.css-cdzivs-MuiButtonBase-root-MuiCheckbox-root.Mui-disabled {
    pointer-events: fill !important;
}

.css-hka5xa,
.css-tghvb1 {
    background-color: #8fd01b !important;
    border: 1px solid #78b83a !important;
}