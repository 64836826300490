.trustee tr {
  display: table-row;
  border: 1px solid #ccc;
  padding: 12px 24px;
  font-size: 16px;
}
.trustee td,
.trustee th {
  width: 50%;
  padding: 10px 24px;
  font-size: 15px;
}
.trustee th {
  padding: 12px 24px;
  border: 1px solid #ccc;
}
