.religious-subtitle2 {
  font-size: 22px;
  font-weight: 700;
  padding: 0;
  color: var(--sub-title);
  margin-right: 6px;
  line-height: 32px;
}
.booked-btn {
  background-color: #b10819 !important;
  color: #fff !important;
  border-bottom: 1px solid #fff !important;
}
.avail-btn {
  background-color: #4caf50 !important;
  color: #fff !important;
  border-bottom: 1px solid #fff !important;
}
.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 12px 10px 10px !important;
  text-align: left;
}
.css-1laqsz7-MuiInputAdornment-root {
  margin-left: -34px !important;
}
