.equal-height {
  display: grid;
}
.service-title {
  font-weight: 700;
  font-size: 21px;
  color: #db4242;
}
.max-size {
  min-height: 250px;
}
.img-fit {
  object-fit: cover;
}
.room-item {
  box-shadow: 0 0 45px rgba(0, 0, 0, 0.08);
}
.position-relative {
  position: relative !important;
}
.h-100 {
  height: 100% !important;
}
.btn2 {
  font-weight: 500;
  transition: 0.5s;
  padding: 10px 23px !important;
  font-size: 15px;
}
.btn.btn-primary,
.btn.btn-secondary {
  color: #fff;
}
.cost {
  font-size: 27px;
  font-weight: 700;
  padding: 6px 0 0;
  color: var(--sub-title);
  margin-right: 6px;
  line-height: 32px;
}
.qty-btn {
  background-color: #fea116 !important;
  border-color: #fea116 !important;
}
.text-body {
  color: #666565 !important;
}
.bg-light {
  background-color: #f1f8ff !important;
  padding: 32px 28px;
}
.img-full {
  width: 100%;
}
.service-view-btn {
  width: 150px;
  float: right;
  padding: 12px 25px !important;
  font-weight: 500;
}
.img-ser-detail {
  height: 366px;
}
.css-i44wyl {
  margin-right: 10px !important;
}
