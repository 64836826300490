.error-h1 {
  font-size: 45px;
  font-weight: 700;
  margin-bottom: 14px;
}

.error-h3 {
  font-size: 28px;
  font-weight: 400;
  margin-bottom: 28px;
}

.error-cont {
  font-size: 18px !important;
  font-weight: 400;
  color: #646464;
}

.erron-cont-bdr {
  border: 1px solid #ccc;
  padding: 68px 34px 58px;
}


.custom-scrollbar {
  scrollbar-width: thin;
  /* For Firefox */
  scrollbar-color: #980b38 #fff;
  /* For Firefox */
}