.btn-align {
  display: block !important;
}
.sent-color {
  color: #49a900 !important;
  font-size: 26px !important;
}
.message-bg {
  background: #f9f9f9;
  padding: 14px;
  border: 1px solid #e2e2e2;
  text-align: center !important;
}
