.religious-services-box {
  margin-bottom: 24px;
  border: 1px solid #edf1ff;
}
.religious-services-title {
  color: var(--primary) !important;
}
.religious-subtitle {
  font-size: 22px;
  font-weight: 700;
  padding: 6px 0 0;
  color: var(--sub-title);
  margin-right: 6px;
  line-height: 32px;
}
.religious-subtitle span {
  font-size: 13px;
  color: #73869f;
  display: block;
  font-weight: 400;
  line-height: 22px;
}
.religious-h5 {
  font-size: 17px;
  color: #0e2b3d;
  font-weight: 600;
  margin-bottom: 0px;
  padding-top: 6px;
}
.spanp {
  color: var(--primary);
}
.success-heading {
  color: #00a905;
  font-weight: 600;
}
.religious-content {
  cursor: pointer;
}
.empty-btn {
  background-color: var(--sub-title);
  border-radius: 4px;
  color: #fff;
  font-size: 12px;
  height: 34px;
  padding: 3px 6px;
  width: 124px;
  text-align: center;
}
.empty-btn:hover {
  color: var(--color2);
}
.download-link {
  font-size: 13px;
}
.religious-bdr {
  border-top: 1px solid #cda0ad;
}

.religious-service-item {
  box-shadow: 0 0 45px rgba(0, 0, 0, 0.07);
  border: 1px solid transparent;
  transition: 0.5s;
}
.religious-service-item h4 {
  font-size: 18px;
  font-weight: 600;
  color: var(--sub-title);
}
.religious-btn {
  padding: 6px 18px !important;
  border-radius: 4px;
  background-color: var(--sub-title);
  font-size: 15px;
}
.time-icon {
  font-size: 15px !important;
  margin-right: 4px;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 11px 12px 10px !important;
  font-size: 13px !important;
}
.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  font-size: 15px !important;
  font-family: "Poppins", sans-serif !important;
  font-weight: 500 !important;
  line-height: 15px !important;
}
.input-group {
  top: 0px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 80px;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}
.bg-secondary {
  background-color: #edf1ff !important;
  height: 47px !important;
  font-size: 16px !important;
  font-weight: 700;
}
.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}
.input-group > .form-control {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}
.text-center {
  text-align: center !important;
}
.btn-sm,
.btn-group-sm > .btn {
  padding: 12px 6px;
  line-height: 1.5;
  border-radius: 0;
}

.btn-primary {
  color: #212529;
  background-color: #d19c97;
  border-color: #d19c97;
}

.details-bg {
  background-color: #f8f9fa;
  padding: 0 14px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 14px;
  font-weight: 500;
}
.religious-service-product h5 {
  padding-top: 6px;
  font-size: 18px;
  font-weight: 600;
  color: var(--sub-title);
  padding-bottom: 8px;
}
ul.detail-list {
  margin-bottom: 16px;
  padding: 0 !important;
  text-align: left;
}
ul.detail-list li {
  color: #010101;
  list-style: none !important;
  line-height: 18px;
  padding: 6px 0 7px !important;
  font-size: 14px;
  font-weight: 400;
}
ul.detail-list li span {
  font-size: 14px !important;
  float: right;
}
.service-details-book {
  font-size: 13px;
  color: var(--primary) !important;
  font-weight: 500;
}
.service-details-book-input {
  padding-left: 12px;
  line-height: 16px;
}
.service-details-total {
  font-size: 16px !important;
  font-weight: 600 !important;
  border-bottom: 1px solid #ccc;
  border-top: 1px solid #ccc;
  line-height: 34px !important;
  display: flow-root !important;
}
.service-details-total span {
  font-size: 16px !important;
  font-weight: 600 !important;
}
.service-download {
  display: block;
  text-align: center;
  font-weight: 500;
  font-size: 15px !important;
  margin-top: 8px;
}
.service-download a {
  color: #0e72ed;
}
.service-content {
  font-size: 15px !important;
  line-height: 23px;
  color: #545454;
}
.service-content-bdr {
  border-top: 1px solid #e4e4e4;
  padding: 8px 0 0 0;
  margin-top: 2px;
  font-size: 13.5px;
  line-height: 17px;
  color: #6a6b6a;
}

.css-ta84vm-MuiAccordionDetails-root {
  /* padding: 0 10px !important; */
  padding: 0 !important;
  border-top: none;
}
.css-1fderfv-MuiPaper-root-MuiAccordion-root {
  background-color: transparent !important;
  border: none !important;
}
.css-uvvswg-MuiButtonBase-root-MuiAccordionSummary-root {
  background-color: transparent !important;
}
.css-ahj2mt-MuiTypography-root {
  color: #b10819 !important;
  font-weight: 600 !important;
}
.css-v84d5j-MuiSvgIcon-root {
  color: #b10819 !important;
}

.truncate p {
  display: -webkit-box;
  /* -webkit-line-clamp: 4; */
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 21px;
  text-align: left;
}

.custom-checkbox-input {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.css-uvvswg-MuiButtonBase-root-MuiAccordionSummary-root
  .MuiAccordionSummary-content {
  line-height: 40px !important;
}
.css-1betqn-MuiAccordionSummary-content {
  margin: 6px 0 6px 8px !important;
}
.css-feqhe6 {
  margin-bottom: 14px !important;
}
.css-17vbkzs-MuiFormControl-root-MuiTextField-root {
  margin-top: 2px !important;
}
.css-uvvswg-MuiButtonBase-root-MuiAccordionSummary-root {
  align-items: self-start !important;
  padding: 0px !important;
}
.css-1ex1afd-MuiTableCell-root {
  padding: 6px !important;
  font-size: 14px !important;
}
.td-padding {
  padding: 0px !important;
}
.css-yw020d-MuiAccordionSummary-expandIconWrapper {
  position: relative;
  top: 15px;
}
.td-border-btm {
  border: none !important;
}
.td-right {
  text-align: right !important;
  font-weight: 500 !important;
  color: #010101 !important;
}
.td-span {
  display: inline;
}
.css-1jo1f9u-MuiButtonBase-root-MuiIconButton-root {
  padding: 0px !important;
}
@media screen and (max-width: 768px) and (min-width: 360px) {
  .text-secundary {
    font-size: 22px !important;
  }
  .bg-secondary {
    height: 43px !important;
  }
}
