.tab-btn {
  text-transform: initial !important;
  font-weight: 600 !important;
  font-family: var(--body-font-family) !important;
}
.css-1a4cg4j-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #0d0d0d !important;
}
.css-11yukd5-MuiTabs-indicator {
  background-color: #0d0d0d !important;
}
.css-1gv0yup-JoyTypography-root {
  font-size: 13px !important;
  padding-left: 12px !important;
  padding-bottom: 5px;
}
.css-1lijw7u-JoyCard-root {
  border: none !important;
  background-color: transparent !important;
  --Card-padding: 0px !important;
}
.css-11c5ebo-JoyTypography-root {
  font-size: 14px !important;
}
.css-n3pxpz-JoyTypography-root {
  font-size: 13px !important;
}
.css-1lijw7u-JoyCard-root {
  display: table-cell !important;
  padding: 5px !important;
}