.search-bar {
  /* border: 1px solid #e4e4e4; */
  border-radius: 6px;
  height: 45px;
  margin-bottom: 12px;
  background-color: #fffdf5 !important;
  border: 1px solid #d3c8bb !important;
  color: #000 !important;
}
.search-title {
  font-size: 19px;
  font-weight: 600;
  color: var(--sub-title);
}
.search-border {
  padding: 20px 0px !important;
}
.search-tab {
  border-radius: 0 !important;
  margin: 0 !important;
  font-size: 15px !important;
  background-color: #f9f9f9 !important;
  border: 1px solid #e4e4e4 !important;
  text-transform: initial !important;
}
.search-icon {
  color: var(--sub-title);
  cursor: pointer;
  z-index: 999;
}
.search-link {
  font-size: 13.5px;
  color: #1a0dab !important;
  font-weight: 400;
  cursor: pointer;
  word-break: break-all;
}
