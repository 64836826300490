.css-2hph54-MuiTypography-root,
.css-1miy0lu-MuiTypography-root,
.css-rizt0-MuiTypography-root,
.css-nwsdb5-MuiFormLabel-root {
  font-family: var(--font-family) !important;
}
.css-1dc80h3-MuiTableCell-root {
  color: #582ecf !important;
}
.home-container {
  display: flex;
  width: 100%;
  height: 100%;
}
.content-container {
  /* background-color: aquamarine; */
  flex: 1 1 50%;
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
}

.image-container {
  flex: 1 1 50%;
  background-image: url("../../assets/images/temple-bg.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.content-header {
  flex: 1 1 20%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.content-header p {
  color: var(--color-text);
  font-weight: 400;
  font-size: 1.5rem;
}

.content-header .link {
  text-decoration: none;
  color: var(--color-red);
}

.logo {
  color: var(--color-red);
  font-weight: 500;
  font-size: 3rem;
}

.outlet-container {
  /* background-color: aqua; */
  flex: 1 1 80%;
  width: 70%;
  align-self: center;
}
.css-nwsdb5-MuiFormLabel-root {
  font-weight: 500 !important;
  font-size: 14px !important;
  margin-bottom: 4px;
}
.css-16wblaj-MuiInputBase-input-MuiOutlinedInput-input:invalid {
  font-family: var(--font-family) !important;
  font-size: 14px;
}
.btn {
  background-color: var(--primary) !important;
  border: 1px solid var(--primary) !important;
  color: var(--white) !important;
  font-size: 15px !important;
  padding: 10px 18px !important;
  text-transform: initial !important;
  font-weight: 600 !important;
  font-family: var(--font-family) !important;
}
.sub-ttl {
  font-size: 19px !important;
  font-weight: 500 !important;
}
.ttl {
  font-weight: 800 !important;
  outline: none;
  background: linear-gradient(
    135deg,
    #5335cf 0%,
    #de005e 25%,
    #f66e48 50%,
    #de005e 75%,
    #5335cf 100%
  );
  background-size: 300%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: animate 10s linear infinite;
  font-size: 28px !important;
  margin-bottom: 5px !important;
  letter-spacing: -0.2px !important;
}
.tbl-h {
  font-size: 15px !important;
  font-weight: 600 !important;
  color: rgba(0, 0, 0, 0.7) !important;
  font-family: var(--font-family) !important;
}
.tbl-row {
  font-family: var(--font-family) !important;
}
.p2 {
  font-size: 14px !important;
  font-weight: 500 !important;
}
@media only screen and (max-width: 998px) {
  .image-container {
    display: none;
  }
  .home-container {
    flex-direction: column;
  }
}

@media only screen and (max-width: 400px) {
  .content-container {
    padding: 2rem;
  }
  .content-header p {
    padding-top: 0.8rem;
  }
}
