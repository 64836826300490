.css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label {
  font-weight: 400 !important;
}
.css-4jnixx-MuiStack-root {
  overflow: hidden !important;
}
.dur-title {
  color: #525252;
  font-weight: 600;
  margin: 0;
}
.radio-btn-mrgn {
  margin-bottom: 0;
  line-height: 20px !important;
}
.pay-btn {
  font-size: 16px !important;
  font-weight: 600;
}
.title-p {
  font-weight: 600;
}
.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  padding-right: 22px !important;
}
.others {
  margin-top: 0 !important;
  position: relative;
  top: -8px;
}
.radio-rgt {
  margin-right: 2px !important;
}
.pledge {
  text-align: center;
  border: 6px solid #dedcdc;
  padding: 22px;
  background-color: #fffcf8;
}
.pledge-h2 {
  font-size: 32px;
  font-weight: 700;
  color: #f00;
  margin: 0;
}
.pledge-h3 {
  font-size: 24px;
  font-weight: 700;
  color: #01a109;
}
.pledge-h4 {
  font-size: 16px;
  font-weight: 600;
  color: #929292;
}
.css-1e7cuzv-MuiFormControl-root{min-width:100%!important;margin: 0px!important;}
.css-j204z7-MuiFormControlLabel-root{margin-left: 0px!important;}
.css-ffg8md-MuiNativeSelect-select-MuiInputBase-input-MuiOutlinedInput-input{padding:17px 0 10px 0!important;}
.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input{padding: 16px 10px 13px !important;}
.css-i4bv87-MuiSvgIcon-root{font-size: 28px!important;}
.css-1jo1f9u-MuiButtonBase-root-MuiIconButton-root{margin-right: 4px!important;}
.css-1pe4mpk-MuiButtonBase-root-MuiIconButton-root:hover{background-color: transparent!important;}