.icon-left {
  margin-left: 30px;
}
.footer {
  background: linear-gradient(
      rgba(187, 157, 165, 0.9),
      rgba(187, 157, 165, 0.9)
    ),
    url(../../../images/footer-bg.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.footer .footer-item a {
  line-height: 18px;
  color: var(--title);
  /* transition: 0.5s; */
  /* word-wrap: break-word; */
  text-decoration: none;
  display: ruby-text;
  margin-bottom: 10px;
}

.footer .footer-item p {
  line-height: 24px;
  font-size: 16px !important;
}

.footer .footer-item a:hover {
  /* letter-spacing: 1px; */
  color: var(--sub-title);
}

.footer .footer-item span:hover {
  /* letter-spacing: 1px; */
  color: var(--sub-title);
}

.footer-icon {
  font-size: 14px;
  margin-right: 6px;
  color: var(--primary);
  width: 22px !important;
  margin-left: -32px;
}

ul.footer-icon-list {
  margin: 0;
  padding: 0;
}

ul.footer-icon-list li {
  margin: 0;
  padding: 0;
  display: inline-block;
  margin-right: 14px;
}

ul.footer-icon-list li a {
  color: #ecbd00;
}

.address-list {
  line-height: 20px !important;
  margin-bottom: 18px;
}

.input-field {
  background-color: var(--white);
  border-radius: 5px;
  font-size: 14px !important;
  font-weight: 600 !important;
  width: 100%;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  height: 19px !important;
}

.footer-btn {
  background-color: var(--primary) !important;
  font-weight: 500 !important;
  font-family: var(--body-font-family) !important;
  font-size: 15px !important;
}

.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root {
  background: #fff;
  padding: 0 6px !important;
  color: var(--primary) !important;
  border-radius: 5px;
}

.mb-2b {
  margin-bottom: 12px !important;
}

.footer h4 {
  color: var(--sub-title);
  font-size: 18px;
}

.copyright {
  background-color: var(--primary);
  font-size: 14px;
  letter-spacing: 0.5px;
}

.copyright a {
  color: #ecbd00;
}

.cursor-pointer {
  cursor: pointer;
}
