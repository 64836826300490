/* object[type="application/pdf"] {
  pointer-events: none; 
}  */

/* Hide title bar, page number, and download button */
object[type="application/pdf"] > embed {
  display: none !important;
}

.cart-item {
  box-shadow: 0 0 45px rgba(0, 0, 0, 0.08);
  padding-bottom: 56px;
}
.css-4jnixx-MuiStack-root > .MuiTextField-root {
  min-width: auto !important;
}
.timeline-top {
  margin-top: 6px;
}
.additional {
  line-height: 18px;
  display: flex;
}
.p-text {
  margin-bottom: 3px;
}
.p-text span {
  font-size: 13.5px !important;
  display: block;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.6);
}
.css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label {
  font-size: 15px !important;
  color: #545454 !important;
  line-height: 18px;
}
.label-list {
  display: block !important;
}
.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root {
  padding: 0 6px !important;
  margin: 0;
}
.attending {
  position: relative;
  top: 10px;
}
.m-right {
  margin-right: 16px !important;
}
.img-height {
  max-height: 410px;
  height: 100%;
}
.price-right {
  color: var(--primary) !important;
  font-weight: 600;
  font-size: 16px !important;
}
label[data-shrink="false"]
  + .MuiInputBase-formControl
  .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  background-color: #fff;
}
label[data-shrink="false"]
  + .MuiInputBase-formControl
  .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  background-color: #fff !important;
}
.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
  background-color: #fff !important;
}
.select-top {
  margin-top: 14px !important;
}
.css-160unip > .MuiTextField-root {
  min-width: 150px !important;
}
.mrgn-btm2 {
  margin-bottom: 8px;
}
.rental-basket {
  height: fit-content;
  position: -webkit-sticky;
  position: sticky;
  top: 20px;
}

@media screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .rent-btn {
    width: 240px;
    height: 50px;
    margin-left: 18px;
    line-height: 15px;
    font-size: 13px !important;
    padding: 0px !important;
  }
  .estimate-btn {
    font-size: 12px !important;
    margin-left: 14px !important;
    padding: 8px !important;
  }
}

@media screen and (min-device-width: 481px) and (max-device-width: 768px) {
  .rent-btn {
    width: 240px;
    height: 48px;
    margin-left: 20px;
  }
}
