ul.monthlyevents-list {
  margin: 0;
  padding: 0 !important;
  text-align: left;
}
ul.monthlyevents-list li {
  list-style: none !important;
  margin-bottom: 6px;
  border-bottom: 1px solid #d4d2d3;
  line-height: 18px;
  font-size: 14px;
  padding: 3px 0 7px !important;
  color: #727272;
}
ul.monthlyevents-list li a {
  color: #1976d2;
  cursor: pointer;
}
ul.monthlyevents-list li a:hover {
  color: var(--primary);
}
ul.monthlyevents-list li div span {
  font-size: 14px;
  width: auto;
  float: left;
  color: #2a1c3a;
  margin-right: 20px;
}
.monthly-event-bg {
  background-color: #fff;
}
.css-h0q0iv-MuiButtonBase-root-MuiTab-root {
  min-height: auto !important;
  background-color: #fff !important;
  margin-right: 2px !important;
  padding: 3px 16px !important;
}
.css-h0q0iv-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: var(--primary) !important;
}
.css-1ujnqem-MuiTabs-root {
  min-height: 30px !important;
}


