@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
  /*--primary: #7E4555;*/
  --primary: #980b38;
  --text-color: #243238;
  --text-color2: #0e2b3d;
  --content: #2b2e4a;
  --title: #100e34;
  --title2: #44425a;
  --white: #ffffff;
  --sub-title: #b10819;
  --box-title: #db4242;
  --color2: #ffc107;
  --heading-color: #282938;
  --bg-shade: #f5fcff;
  --darkblue: #1c1e53;
  --breadcrumb-bg: #f2efe8;
  --sub-title2: #a41e35;
  --body-font-family: "Poppins", sans-serif;
}

* {
  min-width: 0;
  box-sizing: border-box;
  transition: all 0.3s ease-in;
}
*,
:after,
:before {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}
[class*="Mui"] {
  transition: unset;
}
::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  border-radius: 20px;
}
::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: lightgray;
}

html {
  scroll-behavior: smooth;
}

body {
  padding: 0;
  margin: 0;
  font-weight: 400;
  line-height: 27px !important;
  font-family: var(--body-font-family) !important;
}
p {
  font-family: var(--body-font-family) !important;
  font-size: 15.5px !important;
  font-weight: 400;
}
p span {
  font-family: var(--body-font-family) !important;
  font-size: 14.5px !important;
  font-weight: 400;
}
p[class*="MuiFormHelperText"] {
  font-size: 0.75rem !important;
}
p span[class*="MuiFormHelperText"] {
  font-size: 0.75rem !important;
}
h3 span {
  font-family: var(--body-font-family) !important;
}
a {
  text-decoration: none !important;
  transition: 0.3s;
}
ul,
li {
  list-style: none !important;
  padding: 0 !important;
}

a:hover,
a:active,
a:focus {
  outline: none;
  text-decoration: none;
}
a:focus {
  background-color: none !important;
}
.img-max {
  max-width: 100%;
  height: auto;
}

.religious-service-item img {
  width: auto;
  height: 310px;
}
.css-ailhql {
  padding: 0 !important;
}
.css-19kzrtu {
  padding: 0 !important;
}

@media only screen and (max-width: 499px) and (min-width: 320px) {
  .pancgan-table {
    height: 510px !important;
  }
  .btn {
    font-size: 14px !important;
    padding: 10px 8px !important;
  }
  .bg-body-tertiary {
    padding: 0px !important;
  }
  .panchang-content1 {
    padding: 0 0 0 4px !important;
  }
  .panchang .sub-title-bg {
    padding: 5px 0 1px !important;
  }
  .panchang-top-pad {
    padding-top: 20px !important;
  }
  .panchang .title-bg {
    padding: 10px 0 4px !important;
  }
  .carousel-caption {
    width: 54%;
  }
  .slider-btn {
    font-size: 10px !important;
    padding: 5px 12px;
  }
}
@media only screen and (max-width: 769px) and (min-width: 320px) {
  .btn {
    font-size: 14px !important;
    padding: 10px 8px !important;
  }

  .slider-h1 {
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.5px;
    line-height: 14px;
  }
  .carousel-item {
    width: 118% !important;
  }
  .bg-body-tertiary {
    padding: 0px;
  }
  .newsupdate-p {
    font-size: 12px !important;
    line-height: 15px !important;
    font-weight: 500;
  }

  .sidenav {
    top: 52% !important;
  }
  .newsupdate-top {
    font-size: 18px;
  }

  .about .about-content p {
    font-size: 15px !important;
    line-height: 23px;
  }
  p,
  p span {
    font-size: 14.5px !important;
    line-height: 24px;
  }
  .news-title {
    font-size: 16px;
  }
  .logo img {
    width: 120px !important;
  }
  .latestnews {
    width: 100% !important;
  }
  .newsupdate-top {
    margin: 0;
    padding: 4px 0 5px !important;
  }
  .newscroll {
    border-right: none !important;
  }
  .panchang h2 {
    font-size: 16px !important;
  }
  .navbar-brand {
    padding: 0px;
  }
  .slider-h3 {
    font-size: 12px;
  }

  .news-title-bg-title {
    font-size: 16px !important;
    font-weight: 500 !important;
  }
}

.trustee td {
  font-weight: 400;
  white-space: initial;
}

.trustee th {
  font-weight: 600;
}

@media only screen and (max-width: 769px) and (min-width: 320px) {
  .trustee tr {
    display: grid;
    padding: 0 !important;
  }
  .col2 {
    display: inline-flex !important;
    padding: 0 !important;
    margin-top: 12px;
  }
  .about-content-pdg {
    padding: 0px !important;
  }
  .nav-link {
    padding: 5px 0 !important;
  }
}

@media screen and (min-device-width: 992px) and (max-device-width: 1399px) {
  .logo {
    width: 120px !important;
    margin: 5px 12px 0 5px !important;
  }
  .logo img {
    width: 120px !important;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding: 0 !important;
    margin: 0 !important;
    font-size: 14.5px !important;
  }
  .navbar-expand-lg .navbar-nav {
    justify-content: space-around !important;
  }
}

@media screen and (min-device-width: 768px) and (max-device-width: 1199px) {
  .css-z3c6am-MuiFormControl-root-MuiTextField-root {
    width: 120px !important;
  }
  .css-z3c6am-MuiFormControl-root-MuiTextField-root {
    width: 100px !important;
    margin-right: 14px !important;
  }
  .religious-subtitle {
    width: 70px;
  }
}
