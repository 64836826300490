.logo {
  width: 160px;
  margin: 10px 12px 0 10px;
}
.css-4bx69l-MuiButtonBase-root-MuiListItemButton-root.Mui-selected:hover,
.css-4bx69l-MuiButtonBase-root-MuiListItemButton-root.Mui-selected {
  background-color: var(--primary) !important;
  color: var(--white);
  border: 1px solid var(--primary) !important;
}
.css-4bx69l-MuiButtonBase-root-MuiListItemButton-root {
  text-align: center !important;
  border-radius: 6px !important;
  border: 1px solid var(--color-border) !important;
  margin: 0 8px !important;
  width: 150px;
}
.css-rizt0-MuiTypography-root {
  font-weight: 600 !important;
  font-size: 16px !important;
}
.donate-ttl {
  font-size: 24px !important;
  font-weight: 600 !important;
  margin-left: 12px !important;
  font-family: var(--font-family) !important;
  color: var(--title) !important;
}
.dnt-sub-ttl {
  font-size: 18px !important;
  font-weight: 500 !important;
  margin-left: 12px !important;
  font-family: var(--font-family) !important;
  color: var(--title) !important;
}

.paymode-btn {
  background-color: #fff4f8 !important;
  border: 1px solid #fff4f8 !important;
  color: var(--title) !important;
  font-size: 15px !important;
  padding: 11px 20px !important;
  text-transform: initial !important;
  font-weight: 600 !important;
  font-family: var(--font-family) !important;
  margin-right: 14px !important;
  box-shadow: none !important;
}
.css-1iw3t7y-MuiFormControl-root {
  min-width: 280px !important;
  margin-right: 12px !important;
}
.css-imrjgg-MuiButtonBase-root-MuiCheckbox-root {
  padding: 4px 10px !important;
}
.ser-sub-ttl {
  font-size: 17px !important;
  font-weight: 600 !important;
  font-family: var(--font-family) !important;
  color: var(--title) !important;
}
.ser-amt {
  font-size: 15px !important;
  font-weight: 500 !important;
  font-family: var(--font-family) !important;
  color: rgba(0, 0, 0, 0.6) !important;
  width: 90px;
}
.p-pad {
  padding-top: 14px;
}
.ser-price {
  font-weight: 600 !important;
  color: var(--primary) !important;
}
.ser-price-clr {
  color: var(--primary) !important;
}
.price-btn {
  border: 1px solid var(--primary) !important;
}
.css-1ll44ll-MuiOutlinedInput-notchedOutline {
  border-color: var(--primary) !important;
}
.css-172o7q7-MuiTypography-root-MuiDialogTitle-root {
  font-family: var(--font-family) !important;
  font-weight: 600 !important;
  color: #4e4e4c !important;
  padding-top: 48px !important;
}
.pop-ttl {
  font-size: 16px !important;
  line-height: 21px !important;
  font-weight: 600 !important;
  font-family: var(--font-family) !important;
  color: #4e4e4c !important;
  margin-top: 8px !important;
}
/* tab */
.css-1usuzwp-MuiButtonBase-root-MuiTab-root.Mui-selected {
  background-color: var(--primary) !important;
  border: 1px solid var(--primary) !important;
  color: var(--white) !important;
  border-radius: 4px !important;
  font-family: var(--font-family) !important;
  text-transform: initial !important;
}
.css-1usuzwp-MuiButtonBase-root-MuiTab-root {
  border-radius: 4px !important;
  font-family: var(--font-family) !important;
  text-transform: initial !important;
  border: 1px solid var(--color-border) !important;
  font-size: 16px !important;
}
.css-1qltlow-MuiTabs-indicator {
  background-color: transparent !important;
}

.css-19kzrtu {
  padding: 6px !important;
}
.css-6m3xq3-MuiGrid2-root {
  border-left: 14px solid var(--primary) !important;
}
