.payment-container {
  color: var(--primary);
}
.sub-title-h5 {
  padding-top: 6px;
  font-size: 20px;
  font-weight: 600;
  color: var(--sub-title);
  padding-bottom: 8px;
}
ul.payment-detail li {
  color: #343a40;
  list-style: none !important;
  line-height: 23px;
  padding: 6px 0 7px !important;
  font-size: 15.5px;
}
ul.payment-detail li span {
  font-size: 16px !important;
  float: right;
  font-weight: 600;
  color: #010101;
}
.product-title {
  font-size: 16px !important;
  font-weight: 600 !important;
}
