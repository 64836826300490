.temple-title2 {
  font-size: 17px;
  font-weight: 600;
  color: var(--sub-title);
  text-align: left;
}
.list_no {
  padding: 0 !important;
  margin-top: 8px;
}
.list_no li {
  font-size: 16px;
  margin-left: 50px;
  line-height: 23px;
  list-style-type: decimal !important;
  margin-bottom: 10px;
  padding-left: 4px !important;
  text-align: left;
}

/*** registration form ***/
.box-border1 {
  border: 1px solid #ccc;
  background-color: #f7f7f7;
  padding: 34px;
}
.box-border {
  border: 1px solid #e4e4e4;
  background-color: #fff;
  padding: 30px;
  text-align: left;
}

.list-number {
  padding: 0 !important;
  margin-top: 8px;
}
.list-number li {
  font-size: 16px;
  margin-left: 50px;
  line-height: 23px;
  list-style-type: decimal !important;
  margin-bottom: 10px;
  padding-left: 4px !important;
  text-align: left;
}
.list4r1 {
  padding: 0 !important;
  margin-top: 8px;
}
.list4r1 li {
  font-size: 16px;
  margin-left: 0px;
  line-height: 23px;
  list-style-type: none !important;
  margin-bottom: 5px;
  padding-left: 4px !important;
  text-align: left;
}
.list4r {
  padding: 0 !important;
  margin-top: 8px;
  margin-left: 26px;
}
.list4r li {
  font-size: 16px;
  margin-left: 40px;
  line-height: 23px;
  list-style-type: square !important;
  margin-bottom: 5px;
  padding-left: 4px !important;
  text-align: left;
}
.centre-align {
  margin: 0 auto;
  background-color: #f7f8f9;
  padding: 34px 36px;
  border: 1px solid #e4e4e4;
}
.report-bal {
  margin: 0 auto;
  padding: 34px 36px;
  border: 1px dashed #ccc;
}
.report-list li {
  font-size: 14px;
  margin-bottom: 4px;
}
