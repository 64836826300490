.timep{color:#7E4555; font-size:15px;font-weight: 500;}		
.timep span{color:#B10819;}	
.timep2{color:#7E4555; font-size:15px;font-weight: 500;}		
.timep2 span{color:#B10819; display: inline-block;}	
.ttl-h2{font-size: 16px; color: var(--sub-title);font-weight: 700;}
.sub-ttl-h2{font-size: 16px; color: var(--sub-title);font-weight:600;}
.ttl-h4{font-size: 16px; color: var(--primary);font-weight: 600;}
.img-res{ max-width: 100%;}

.list-group-item{    border:none; 
    margin-left: 24px;
    line-height: 32px;}

    .list2{margin-bottom: 14px;}
    .list2 li{
    list-style: disc!important;
    padding: 0!important;
    margin-left: 34px;
}    