.slider-text {
  text-align: left !important;
  width: 33% !important;
  left: 14%;
}
.slider-images {
  width: 48% !important;
  position: absolute;
  right: 0;
  top: 20%;
  border: 12px solid #f28a00;
}
.tvgallery-bg1 {
  background-color: #fff6f1;
  height: 100vh;
}
.tvgallery-bg2 {
  background-color: #f6f8fb;
  height: 100vh;
}
.h5_gallery {
  font-size: 52px;
  line-height: 58px;
  font-weight: 600;
  font-family: Poppins;
  margin-bottom: 22px;
}
.tvgallery-list {
  margin-bottom: 14px;
}
.tvgallery-list li {
  margin-bottom: 2px;
  font-weight: 600;
  font-size: 19px;
  display: flex;
  margin-right: 14px;
}
.tvgallery-list li span {
  color: var(--sub-title);
  margin-right: 5px;
}
.gallery-p {
  font-size: 20px !important;
  font-weight: 300;
  line-height: 30px;
}

@media screen and (min-device-width: 349px) and (max-device-width: 924px) {
  .h5_gallery {
    font-size: 18px;
    line-height: 18px;
    font-weight: 600;
  }
  .slider-text {
    width: 45% !important;
    left: 1%;
  }
  .tvgallery-bg1,
  .tvgallery-bg2,
  .tvgallery-bg3 {
    height: 100vh;
  }
  .tvgallery-list li {
    margin-bottom: 12px;
    font-size: 12px;
    line-height: 14px;
  }
  .tvgallery-list {
    line-height: 16px;
    margin-bottom: 8px;
  }
  .gallery-p {
    line-height: 18px;
    font-size: 13px !important;
  }
}
.tvg-left,
.tvg-right {
  height: 50vh;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .tvg-left,
  .tvg-right {
    height: 100vh;
  }
}
