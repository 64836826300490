.gallery-background-home {
  height: 200px;
  width: 320px;
  background-size: cover !important;
  border: #db4242 4px solid;
  border-radius: 20px;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}
.gallery-background-home:hover {
  transform: scale(1.02);
}

.gallery-background-image {
  overflow: hidden;
  border-radius: 20px;
  border: #db4242 4px solid;
  box-shadow: 0 4px 4px -1px rgb(0 0 0 / 0.2), 0 2px 4px -2px rgb(0 0 0 / 0.2);
  cursor: pointer;
}
.event-video-wrapper {
  display: flex;
  flex-wrap: wrap;
}
.gallery-event-video {
  overflow: hidden;
  border-radius: 20px;
  border: #db4242 4px solid;
  box-shadow: 0 4px 4px -1px rgb(0 0 0 / 0.2), 0 2px 4px -2px rgb(0 0 0 / 0.2);
  cursor: pointer;
  line-height: 0;
}
.gallery-background-image img {
  display: block;
  width: 100%;
}
.gallery-background-image:hover {
  transform: scale(1.02);
  box-shadow: 0 10px 15px 2px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}
.yarl__thumbnails_track {
  overflow-x: scroll;
}
.yarl__slide.yarl__slide_current.yarl__flex_center
  .yarl__fullsize.yarl__flex_center.yarl__slide_wrapper {
  animation: fadeIn 2s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
