.panchang-btm {
  padding-bottom: 10px;
}
.panchang .title-bg {
  background-color: var(--primary);
  color: #fff;
  border-radius: 10px 10px 0 0;
  padding: 14px 0 8px;
  height: 60px;
}

.panchang .sub-title-bg {
  background-color: #f5f5f5;
  color: var(--sub-title);
  padding: 8px 0 1px;
  height: 42px;
}
.sub-title-bg span {
  display: block;
  font-size: 14px;
}
.panchang h2 {
  font-size: 17px;
  font-weight: 500;
  padding-top: 5px;
}
.panchang-content {
  padding: 8px 20px;
  background-color: #fff;
  height: 426px;
  /*overflow: scroll;
  overflow-x: hidden;*/
}
.panchang-content2 {
  padding: 8px 20px;
  border: 1px solid #e5e7eb;
  /* background-color: #f2f1f8;*/
  height: 400px;
}
.panchang-content1 {
  margin-top: 14px;
  padding: 0 0 0 20px;
  background-color: #fff;
}
.panchang-top-pad {
  padding-top: 35px;
}
.btn-right {
  margin-right: 15px;
}
.arw-btn {
  color: var(--primary) !important;
}
@media only screen and (max-width: 769px) and (min-width: 320px) {
  .panchang-content {
    height: auto;
  }
}
