.css-1cquhrj-JoyTextarea-root {
  background-color: #fff !important;
  border: 1px solid #c4c4c4;
  margin-top: 10px;
}
.cont-btn {
  padding: 11px 28px !important;
  font-size: 15px !important;
  font-weight: 500 !important;
  font-family: "Poppins" !important;
  letter-spacing: 1px !important;
  background: #b10819 !important;
  box-shadow: none !important;
}
.select-drp {
  margin: 8px 0;
}
