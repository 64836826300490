.prasadam-list {
  padding: 0 !important;
  margin-top: 8px;
}
.prasadam-list li {
  list-style-type: none !important;
  text-align: center;
  display: inline;
  margin: 0 8px;
}
.prasadam-list li a {
  font-size: 16px;
  padding: 8px 18px;
  border: 1px solid #cdcdcd;
  border-radius: 5px;
  color: #0e2b3d;
  font-weight: 400;
}
.prasadam-list li a:hover {
  color: #fff;
  border: 1px solid #cdcdcd;
  background-color: var(--primary);
}
.prasadam td a {
  color: #323232;
}
.prasadam td a:hover {
  color: #db0015;
}
.prasadam td {
  font-weight: 400;
  white-space: nowrap;
  text-align: right;
}
.prasadam td {
  padding: 10px 24px;
  font-size: 15px;
}
.prasadam td:first-child,
.prasadam th:first-child {
  text-align: left;
}
.prasadam tr {
  text-align: right;
  display: table-row;
  border: 1px solid #ccc;
  padding: 12px 24px;
  font-size: 16px;
}
.prasadam th {
  font-size: 15.6px !important;
  padding: 12px 24px;
  border: 1px solid #ccc;
  color: var(--primary) !important;
}
.prasadam td,
.prasadam th {
  width: 50%;
  padding: 10px 24px;
  font-size: 15px;
}

.table1 {
  width: 70%;
  margin: 0 auto;
}
.cafeteria-title {
  font-size: 20px;
  font-weight: 600;
  color: var(--sub-title);
  text-align: center;
  margin: 0;
}
.font-b {
  font-weight: 600 !important;
}
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  font-family: "Poppins", sans-serif !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  text-transform: initial !important;
  color: var(--text-color2) !important;
  border: 1px solid #d3c8bb !important;
  margin: 0 4px !important;
  border-radius: 8px !important;
  background-color: #faf6e7 !important;
}
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: var(--primary) !important;
}
.css-w8dmq8 {
  font-family: "Poppins", sans-serif !important;
}
.css-1gsv261 {
  border-bottom: none !important;
}
.css-1aquho2-MuiTabs-indicator {
  display: none;
}
.input-mrgn {
  margin: 0 12px !important;
}
.css-qfso29-MuiTypography-root-MuiDialogContentText-root {
  font-family: "Poppins", sans-serif !important;
  font-weight: 500 !important;
  color: #323232 !important;
  line-height: 54px !important;
}
.css-bdhsul-MuiTypography-root-MuiDialogTitle-root {
  color: var(--primary) !important;
  font-family: "Poppins", sans-serif !important;
  text-align: center;
  font-weight: 700;
  font-size: 24px;
}
.css-1lg55su-MuiButtonBase-root-MuiButton-root {
  border: 1px solid #ccc !important;
  padding: 8px 20px 6px !important;
}
.css-10fi992-MuiButtonBase-root-MuiButton-root {
  border: 1px solid #ccc !important;
  padding: 8px 20px 6px !important;
}
.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  padding: 22px;
}
.css-1wlk0hk-MuiAvatar-root {
  border-radius: 0px !important;
}
.catering-title {
  font-size: 24px;
  font-weight: 600;
  color: var(--sub-title);
  text-align: center;
  margin: 0;
}

.css-1wniyei-MuiTypography-root {
  font-family: "Poppins", sans-serif !important;
  font-weight: 600 !important;
  font-size: 20px !important;
  line-height: 20px !important;
  letter-spacing: 0.00938em;
  margin-bottom: 8px !important;
}
.css-btlb3t-MuiTypography-root {
  background: #980b38;
  display: inline;
  padding: 10px 14px;
  border-radius: 6px;
  color: #fff;
}

/**  catering **/
.catering-cont {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  color: rgba(0, 0, 0, 0.6);
}
.catering-ttl {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  letter-spacing: 0.00938em;
  margin-bottom: 8px;
}
.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}
.tray {
  font-weight: 600;
  text-align: center;
  margin-bottom: 6px;
}
.tray span {
  font-weight: 500;
  display: block;
  color: rgba(0, 0, 0, 0.6);
}
.box-bg {
  background-color: #fff;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 0;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  overflow: hidden;
  padding: 14px 18px;
  margin-bottom: 28px;
}
.text-rght {
  float: right;
}
ul.quantity {
  padding: 0;
  margin: 0;
}
ul.quantity li {
  border: 1px solid #ebc5bb;
  border-radius: 8px;
  padding: 2px 11px !important;
  display: inline-block;
  margin-right: 6px;
}
ul.quantity li a {
  color: #0e2b3d;
  font-size: 13.5px;
}
.price-btn {
  background-color: #db0015 !important;
  padding: 9px 24px;
  border: 1px solid #db0015 !important;
  margin-top: 4px;
}
.prasad-more {
  font-size: 14px !important;
  letter-spacing: 0.5px;
  color: #db0015;
  cursor: pointer;
}
.cursor {
  cursor: pointer;
}
.h2-ttl {
  font-size: 24px !important;
  font-weight: 600 !important;
  margin-bottom: 4px !important;
}
.h3-ttl3 {
  color: #980b38;
  font-size: 22px !important;
  font-weight: 600 !important;
  margin-bottom: 1px !important;
  text-align: right;
}
.h3-ttl {
  font-size: 16px !important;
  font-weight: 500 !important;
  margin-bottom: 8px !important;
}
.css-153eyq5-MuiModal-root-MuiDialog-root .MuiDialogContent-root {
  padding: 0px !important;
}
.css-h93ljk-MuiTypography-root {
  font-family: "Poppins", sans-serif !important;
  font-weight: 600 !important;
  font-size: 18px !important;
  margin-top: 10px !important;
}
.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  max-width: 920px !important;
}
.css-140puxv-MuiTypography-root-MuiDialogTitle-root {
  padding: 0px !important;
}

.card {
  padding: 16px;
  margin-bottom: 16px;
  margin-top: 16px;
}

.card img {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.catering-ttl2 {
  font-weight: 700 !important;
  margin: 6px 0 10px !important;
  color: #0a0a00;
}

.item-name {
  font-size: 1.1rem;
  margin: 8px 0;
}

.item-price {
  font-size: 1rem;
  margin: 8px 0;
  color: #555;
}

.add-button {
  margin-top: 8px;
}

.breadcrumb-bg {
  background-color: #f8f9fa;
  padding: 16px 0;
}

.breadcrumb-h1 {
  font-size: 2.5rem;
  color: #333;
}

.breadcrumb-item a {
  color: #007bff;
}

.breadcrumb-item.active {
  color: #6c757d;
}

.navbar-light {
  background-color: #e9ecef;
  padding: 8px 16px;
  border-radius: 0px;
}

.navbar-light .btn {
  margin: 4px 0;
}

.tag-btn {
  background-color: #f4f6f8;
  border: 1px solid #ccc;
  color: var(--primary);
  padding: 6px 18px;
  font-size: 14px;
  border-radius: 5px;
  margin-bottom: 8px;
  font-weight: 500;
}
.tag-btn:hover,
.tag-btn.active {
  background-color: var(--primary);
  border: 1px solid var(--primary);
  color: var(--color2) !important;
}
.add-btn {
  background-color: #f4f6f8;
  border: 1px solid #ccc;
  color: var(--primary);
  padding: 6px 18px;
  font-size: 15px;
  border-radius: 5px;
  margin-bottom: 8px;
}
.cafeteria-price {
  font-size: 22px !important;
  font-weight: 700 !important;
  position: relative;
  top: 6px;
}
.wd {
  width: 100%;
}
.w120 {
  width: 100px;
  margin: 8px 0;
}
.catering-ttl {
  font-size: 17px !important;
}
.css-r40f8v-MuiTypography-root {
  font-size: 14px !important;
}
.css-1ic19cg-MuiGrid-root > .MuiGrid-item {
  display: grid;
  grid-auto-flow: column;
}
.css-1ex1afd-MuiTableCell-root {
  /* font-weight: 700 !important; */
  font-family: var(--body-font-family) !important;
  font-size: 14px !important;
  /* font-size: 15px !important; */
  /* padding: 12px !important; */
  padding: 6px !important;
}
.qty-align {
  display: inline-block !important;
  margin-top: 6px !important;
  font-size: 18px;
  font-weight: 600;
}
.tray-align {
  display: block !important;
  color: #6c757d !important;
  font-size: 13.5px !important;
  font-weight: 500 !important;
}
.qty-btn-add {
  background-color: var(--primary) !important;
  border: 1px solid var(--primary) !important;
  color: var(--white) !important;
  padding: 6px 24px !important;
  font-size: 15px !important;
  margin-top: 5px;
}

.qty-add{    background: #ffedd9;
  border-radius: 4px;
  margin-left: 5px;
  padding: 2px;
  color: #4a4a4a;
}
.qty-dec{    background: #ef9090;
  border-radius: 4px;
  margin-right: 5px;
  padding: 2px;
  color: #3a3a3a;
}
.catering-price{font-weight: 600;font-size: 16px;}
.css-1q1u3t4-MuiTableRow-root {
  vertical-align: top !important;
}
.des-content {
  font-size: 13.5px !important;
  color: #7a7a7a !important;
  line-height: 22px !important;
  font-weight: 400 !important;
}
.pagination {
  float: right;
}
.cafeteria-btn{
  margin: 18px 0;
  padding: 8px 30px;
  font-weight: 500;
  font-size: 16px;
}
b, strong {
  font-weight: 600!important;
}
.catering-td{padding: 18px 10px !important;}
.catrg-ttl{font-weight: 600!important; color: #3e353b!important;}
.css-160unip{overflow:hidden!important}