.footer-item h4{    font-size: 18px;
    font-weight: 600;
    color: var(--sub-title);
}
/********* title *********/
.section-title {
    position: relative;
    display: inline-block;
}
.text-primary {
    color:var(--primary) !important;
}
.text-secundary {
    color:#DB4242;
    font-weight: 700;
    letter-spacing: -1px;
}
.title-tl{    
        font-size: 17px;
        font-weight: 700;
        letter-spacing: 0px;
        margin: 0;
        }
.text-uppercase {
    text-transform: uppercase !important;
}
.section-title::before {
    position: absolute;
    content: "";
    width: 45px;
    height: 2px;
    top: 50%;
    left: -55px;
    margin-top: -1px;
    background: var(--primary);
}
.section-title::after {
    position: absolute;
    content: "";
    width: 45px;
    height: 2px;
    top: 50%;
    right: -55px;
    margin-top: -1px;
    background: var(--primary);}