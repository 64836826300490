/*** Service ***/
.service-item {
  box-shadow: 0 0 45px rgba(0, 0, 0, 0.07);
  border: 1px solid transparent;
  transition: 0.5s;
}

.service-item:hover {
  margin-top: -10px;
  box-shadow: none;
  border: 1px solid #dee2e6;
}

.service-list a {
  margin-bottom: 7px;
  padding: 12px 15px;
  border-radius: 6px 30px 30px 6px;
  transition: 0.5s;
}
.service-list a:last-child {
  margin-bottom: 0;
}
.text-secundary {
  color: #db4242;
  font-weight: 700;
  letter-spacing: -1px;
}
.service-list a:hover {
  color: var(--light) !important;
  background: var(--primary) !important;
}

.service-list a::before {
  position: relative;
  content: "\f105";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin-right: 0;
  transition: 0.5s;
  opacity: 0;
}

.service-list a:hover::before {
  opacity: 1;
  margin-right: 10px;
}

.service-item h4 {
  font-size: 18px;
  font-weight: 600;
  color: var(--sub-title);
}
.service-item2 {
  font-size: 16px !important;
  font-weight: 700 !important;
  color: var(--sub-title);
}
.home-ser {
  background-color: #f6f8fa;
}
.service-item {
  box-shadow: 0 0 45px rgba(0, 0, 0, 0.09) !important;
  width: 100%;
}
.input-group .btn {
  padding: 10px 8px !important;
}

@media only screen and (max-width: 640px) {
  .list-grid {
    display: flex !important;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .grid-item {
    width: 46%;
  }
  .service-item2 {
    font-size: 13.5px !important;
    font-weight: 500 !important;
  }
}
