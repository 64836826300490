.ttl2-h2 {
  font-size: 21px;
  color: var(--sub-title);
  font-weight: 600;
  margin-bottom: 21px;
}
.events-content {
  margin-bottom: 24px;
}
.latest-news li {
  border-bottom: 1px solid #c4c4c4;
  margin-bottom: 0px;
  padding: 8px 0 6px 0 !important;
  font-size: 15px;
  line-height: 28px;
}
.latest-news li a {
  color: #878787;
}
.latest-news li a:hover {
  color: var(--primary);
}
.hr2 {
  padding: 16px 0;
}
.devider {
  text-align: center;
  padding: 22px 0 46px;
}
