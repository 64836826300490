.profile-h4 {
    font-size: 18px;
    font-weight: 600;
    color: var(--sub-title);
    margin: 8px 0 12px;
}
.css-md26zr-MuiInputBase-root-MuiOutlinedInput-root {
    margin-bottom: 14px;
}
.css-q8hpuo-MuiFormControl-root {
    margin: 0px 0 5px !important;
}
.css-160unip > .MuiTextField-root {
    min-width: 99% !important;
}
.btn-small {
    font-size: 1rem !important;
    padding: 0.3125rem 0.625rem !important;
}
/* .btn-send-mail {
    background-color: #28a745!important;
    color: white;
    border: none!important;
}
.btn-download {
    background-color: #078fff!important;
    border: none!important;
}   */
    