.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
.popup {
  background-color: white;
  padding: 10px 30px 14px;
  border-radius: 5px;
  width: 90%;
  text-align: center;
  position: absolute;
  top: 8%;
  overflow: scroll;
  min-height: 600px;
}
.closeButton {
  position: relative;
  top: 0px;
  right: 0px;
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
  font-family: cursive;
}
.close-div {
  text-align: right;
}
.close-btn {
  background: #db4242;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  color: #fff;
}
.popup2 {
  padding: 0 !important;
  margin-top: 8px;
}
.popup2 li {
  font-size: 15px;
  margin-left: 32px;
  line-height: 23px;
  list-style-type: decimal !important;
  margin-bottom: 10px;
  padding-left: 4px !important;
  text-align: left;
}
.popup2 li span {
  font-weight: 600;
  color: #b10819;
}
.text-secundary {
  line-height: 34px;
}
@media only screen and (max-width: 1024px) and (min-width: 320px) {
  .popup {
    width: 90%;
    overflow-y: scroll;
    height: 680px;
  }
}
