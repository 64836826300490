.trustee tr {
  display: table-row;
  border: 1px solid #ccc !important;
  padding: 12px 24px !important;
  font-size: 16px;
}
.trustee td {
  padding: 10px 24px !important;
  font-size: 15px;
}
.trustee th {
  padding: 12px 24px !important;
  border: 1px solid #ccc;
}
