.about-temple-bg {
  background-color: #f2f1f8;
}

/********* About *********/
.about,
.single {
  position: relative;
  padding: 0;
}

.about .col-md-12,
.about .col-md-6,
.single .col-md-12,
.single .col-md-6 {
  margin-bottom: 30px;
}

.about .about-img,
.single .single-img {
  position: relative;
  overflow: hidden;
}

.about .about-img img,
.single .single-img img {
  width: 100%;
}
.about .about-content p {
  font-size: 15px;
}
.about .about-content,
.single .single-content {
  position: relative;
  margin: -128px auto 0 auto;
  padding: 32px;
  background: #ffffff;
  border-radius: 20px;
}

.about .about-content h2,
.single .single-content h2 {
  font-size: 20px;
  font-weight: 700;
  color: var(--box-title);
}

.about-content-pdg {
  padding: 0 24px;
}
.col-container {
  display: table;
  width: 100%;
}
.col2 {
  display: table-cell;
  padding: 14px;
}

/********* title *********/
.section-title {
  position: relative;
  display: inline-block;
}
.text-primary {
  color: var(--primary) !important;
}
.text-secundary {
  color: #db4242;
  font-weight: 700;
  letter-spacing: -1px;
}
.title-tl {
  font-size: 17px;
  font-weight: 700;
  letter-spacing: 0px;
  margin: 0;
}
.text-uppercase {
  text-transform: uppercase !important;
}
.section-title::before {
  position: absolute;
  content: "";
  width: 45px;
  height: 2px;
  top: 50%;
  left: -55px;
  margin-top: -1px;
  background: var(--primary);
}
.section-title::after {
  position: absolute;
  content: "";
  width: 45px;
  height: 2px;
  top: 50%;
  right: -55px;
  margin-top: -1px;
  background: var(--primary);
}

@media only screen and (max-width: 600px) and (min-width: 320px) {
  .about .about-content,
  .single .single-content {
    margin: 0;
  }
  .about-content-pdg {
    padding: 0;
  }
}
