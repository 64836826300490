.label-text {
  font-family: "Poppins", sans-serif !important;
  font-size: 15.5px !important;
  font-weight: 600 !important;
  margin-bottom: 4px !important;
  color: rgba(0, 0, 0, 0.8) !important;
}
.css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label {
  font-family: "Poppins", sans-serif !important;
  font-size: 15.5px !important;
}
.css-j204z7-MuiFormControlLabel-root {
  margin-bottom: 10px;
}
.css-10mzr36-MuiGrid-root {
  margin-top: 4px;
}
.css-wai219 {
  font-family: "Poppins", sans-serif !important;
  font-size: 15px !important;
  border-radius: 4px;
  resize: none;
  width: 100% !important;
  color: #ccc !important;
}
.css-sghohy-MuiButtonBase-root-MuiButton-root {
  background-color: var(--primary) !important;
  border: 1px solid var(--primary) !important;
  color: var(--white) !important;
  padding: 10px 18px !important;
  font-size: 15px !important;
  width: 138px;
}
.css-10mzr36-MuiGrid-root {
  margin-top: 5px;
}
.parents-h1 {
  font-size: 20px !important;
  font-weight: 600 !important;
  color: #db4242 !important;
}
.hr-gap {
  border-top: 1px solid #888;
  margin: 18px 0 34px;
}
@media only screen and (max-width: 769px) and (min-width: 320px) {
  .css-105eav3-MuiGrid-root {
    max-width: -webkit-fill-available !important;
  }
}
