.border-btm {
  border-bottom: 1px solid #e4e4e4;
  padding-bottom: 24px;
}
.css-pef4oo-JoyChip-root {
  padding: 6px 22px !important;
}
.pop-content {
  line-height: 30px !important;
}
.css-1t4vnk2-MuiDialogContent-root {
  padding: 8px 24px 24px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none !important;
}
.css-bdhsul-MuiTypography-root-MuiDialogTitle-root {
  padding: 12px 24px 8px !important;
}
.css-bdhsul-MuiTypography-root-MuiDialogTitle-root {
  font-size: 24px !important;
  font-weight: 700 !important;
}
.css-1ygcj2i-MuiTableCell-root {
  font-family: var(--body-font-family) !important;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
}
.table-bdr {
  border: 1px solid #ccc;
}
.tbl-td {
  border: 1px solid #ccc;
}
