.hr-gap2 {
  border-top: 1px solid #888;
  margin: 12px 0 24px;
}
.faq-sub {
  font-size: 16px !important;
  font-style: italic;
  font-weight: 600;
  letter-spacing: 0.3px;
}
.sub-content {
  font-size: 16px !important;
  font-style: italic;
  font-weight: 600;
  letter-spacing: 0.3px;
}
.list4 {
  padding: 0 !important;
  margin-top: 8px;
}
.list4 li {
  font-size: 16px;
  margin-left: 50px;
  line-height: 23px;
  list-style-type: square !important;
  margin-bottom: 10px;
  padding-left: 4px !important;
  text-align: left;
}
