.sidenav {
  position: fixed;
  z-index: 1;
  top: 40%;
  right: 0px;
  overflow-x: hidden;
  padding: 8px 0;
}
/* .sidenav_bg1{background-color:#2196F3;} */
.sidenav_bg1 {
  background-color: #f70000;
}
.sidenav_bg2 {
  background-color: #f1c40f;
}
.sidenav_bg3 {
  /* background-color: #b71b00; */
  background-color: #2196f3;
}
.sidenav_bg4 {
  background-color: #34495e;
}
.sidenav a {
  padding: 12px;
  text-decoration: none;
  font-size: 25px;
  color: #fff;
  display: block;
  margin-bottom: 2px;
}

.sidenav a:hover {
  color: #fff;
}

.main {
  margin-left: 140px;
  font-size: 28px;
  padding: 0px 10px;
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }
  .sidenav a {
    font-size: 18px;
  }
}
