.signup{position: relative; top: 10px; padding: 12px 48px;}
.signbtn{    position: relative;
    top: 12px;}
.signup-btn:hover{padding:12px 38px; color: var(--white);}
.signup-btn-title{font-size: 36px; color: var(--color2); font-weight: 600; margin: 0;}
.signup-btn-sub-title{font-size: 18px; font-weight: 600;}

@media only screen and (max-width: 769px) and (min-width: 320px)  {
    .signup-btn-title {
        font-size: 24px;}
}