.gallery-bg {
  background-color: #f2f1f8;
}
/********* title *********/
.section-title {
  position: relative;
  display: inline-block;
}
.text-primary {
  color: var(--primary) !important;
}
.text-secundary {
  color: #db4242;
  font-weight: 700;
  letter-spacing: -1px;
}
.title-tl {
  font-size: 17px;
  font-weight: 700;
  letter-spacing: 0px;
  margin: 0;
}
.text-uppercase {
  text-transform: uppercase !important;
}
.section-title::before {
  position: absolute;
  content: "";
  width: 45px;
  height: 2px;
  top: 50%;
  left: -55px;
  margin-top: -1px;
  background: var(--primary);
}
.section-title::after {
  position: absolute;
  content: "";
  width: 45px;
  height: 2px;
  top: 50%;
  right: -55px;
  margin-top: -1px;
  background: var(--primary);
}

.image-container {
  position: relative;
  overflow: hidden;
  border-radius: 24px;
  border: #f2f1f8 5px solid;
  box-shadow: 0 4px 1px -1px rgb(0 0 0 / 0.2), 0 2px 4px -2px rgb(0 0 0 / 0.2);
  cursor: pointer;
}
.image-container img {
  display: block;
  width: 100%;
}
.image-container:hover {
  transform: scale(1.02);
  box-shadow: 0 10px 15px 2px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}

.image-overlay {
  position: absolute;
  bottom: -50%;
  left: 0;
  right: 0;
  height: 12%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease-out;
  background-color: rgb(219 66 67 / 50%);
  backdrop-filter: blur(4px) brightness(50%);
}
.image-container:hover .image-overlay {
  bottom: 0;
  opacity: 1;
}
.overlay-text {
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.view-gallery-bar {
  background-color: var(--primary);
  transition: all 0.3s ease-in;
  font-size: 15.5px;
  font-weight: 500;
  padding: 12px 26px;
  letter-spacing: 0.5px;
}
.view-gallery-bar:hover {
  transform: scale(1.01);
}
