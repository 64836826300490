/*** Service ***/
.service-item {
  box-shadow: 0 0 45px rgba(0, 0, 0, 0.07);
  border: 1px solid transparent;
  transition: 0.5s;
}

.service-item:hover {
  margin-top: -10px;
  box-shadow: none;
  border: 1px solid #dee2e6;
}

.service-list a {
  margin-bottom: 7px;
  padding: 12px 15px;
  border-radius: 6px 30px 30px 6px;
  transition: 0.5s;
}
.service-list a:last-child {
  margin-bottom: 0;
}
.text-secundary {
  color: #db4242;
  font-weight: 700;
  letter-spacing: -0.5px;
  font-size: 32px;
}
.service-list a:hover {
  color: var(--light) !important;
  background: var(--primary) !important;
}

.service-list a::before {
  position: relative;
  content: "\f105";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin-right: 0;
  transition: 0.5s;
  opacity: 0;
}

.service-list a:hover::before {
  opacity: 1;
  margin-right: 10px;
}

.service-item h4 {
  font-size: 18px;
  font-weight: 600;
  color: var(--sub-title);
}
