.slider-h1 {
  font-size: 33px;
  font-weight: 600;
  letter-spacing: -0.2px;
  line-height: 42px;
  margin-bottom: 8px;
}
.slider-h3 {
  font-weight: 500;
  color: var(--title);
  font-size: 20px;
  width: 680px;
  text-align: center;
  margin: 0 auto 10px;
}
.sub-title {
  font-weight: 600;
  color: var(--title);
  font-size: 21px;
  line-height: 23px;
  width: 680px;
  text-align: center;
  margin: 0 auto 12px;
}
.sub-title p {
  font-size: 19px !important;
  font-weight: 500;
  line-height: 26px !important;
  text-align: center;
}
.btn-slider {
  font-size: 14.5px !important;
  background-color: var(--primary);
  border: 1px solid var(--primary);
  font-weight: 600;
  letter-spacing: 0.6px;
}
.btn-slider:hover {
  background-color: var(--primary);
  border: 1px solid var(--primary);
  color: var(--color2);
}
.carousel-indicators {
  display: none;
}
.carousel-caption {
  right: 25%;
  top: 20%;
  left: 2%;
}
.btn {
  background-color: var(--primary);
  border: 1px solid var(--primary);
  color: var(--white);
  padding: 10px 18px;
  font-size: 15px;
}
.btn:hover,
.btn:active,
.btn:focus {
  background-color: var(--primary) !important;
  border: 1px solid var(--primary) !important;
  color: var(--color2) !important;
}

@media only screen and (max-width: 769px) and (min-width: 320px) {
    .res-img {
    position: relative;
    right: 45px;
  }
  .sub-title p {
    font-size: 13px !important;
    font-weight: 500 !important;
    line-height: 17px !important;
    margin-bottom: 6px;
  }
  .sub-title {
    font-weight: 500 !important;
    color: var(--title) !important;
    font-size: 14px !important;
    line-height: 20px !important;
    width: 100% !important;
    text-align: left !important;
    margin: 0 auto 6px !important;
  }
  .slider-h1 {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.3px;
    margin-bottom: 4px;
    line-height: 17px;
  }
  .slider-h3 {
    color: var(--title);
    font-size: 11px;
    font-weight: 400;
    line-height: 15px;
    width: auto;
  }
  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    display: none;
  }
  .carousel-caption {
    top: 2%;
  }
  .slider-btn {
    font-size: 12px !important;
    padding: 5px 16px !important;
  }
}
