.receipt-bg {
  max-width: 680px;
  font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 20px;
  background-color: rgb(255, 255, 255);
  padding: 24px;
  border-top: 3px solid rgb(212, 218, 223);
  border: 1px solid rgb(204, 204, 204);
}
.receipt-header {
  text-align: center;
}
.receipt-header h2 {
  font-size: 32px;
  font-weight: 700;
  letter-spacing: -1px;
  color: #980b38;
  margin: 0;
}
.receipt-header h3 {
  font-size: 15px;
  font-weight: 700;
  color: #243238;
}
.receipt-header p {
  font-size: 13px !important;
  font-weight: 600;
  margin-bottom: 1px;
  color: #ff4d16;
  text-align: center;
}
.header-bdr {
  border-bottom: 1px solid #e4e4e4;
}
.total-sum {
  text-align: right;
  font-weight: 600 !important;
}
.footer_clr {
  background: rgb(231, 233, 235);
  font-weight: bold;
  text-align: center;
  padding: 24px;
}
.content-msg {
  font-size: 14.5px !important;
  font-weight: 600;
  margin-bottom: 14px;
  text-align: center;
}
.regards {
  font-size: 14.5px !important;
  font-weight: 600;
  color: #cf0000;
  margin-top: 40px;
}
