.services-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 10px;
}

.service-card {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  text-align: center;
}

.service-card:hover {
  background-color: #f0f0f0;
}

.success-message {
  text-align: center;
}

.tick-animation {
  width: 100px;
}
